import './App.css';
import ThreeBackground from "./components/ThreeBackground/ThreeBackground";
import React, {useState} from "react";
import AlternatingSections from "./components/AlternatingSections/AlternatingSections";
import Footer from "./components/Footer/Footer";
import RevenueCards from "./components/RevenueCards/RevenueCards";

function App() {
  return (
    <div className="App">
      <ThreeBackground />
      <header>
        <nav>
            <div className="wrapper">
                <div className="logo">
                    <img src="/logo.svg" alt="" className="logo" />
                    SunTools
                </div>
                <ul className='nav-list'>
                    <li>
                        <a href="https://t.me/SunToolsPortal" className="nav-list-link">Telegram</a>
                    </li>
                    <li>
                        <a href="https://x.com/SunToolsTron" className="nav-list-link">Twitter</a>
                    </li>
                    <li>
                        <a href="https://docs.suntools.tech" className="nav-list-link">Docs</a>
                    </li>
                    <li>
                        <a href="https://sun.io/?lang=en-US#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&t1=THacKMJfhRAzB2V7n6dR9Mm2w47cCaWWR3&type=swap&from=v3" className="button button-secondary">Buy $TOOLS</a>
                    </li>
                </ul>
            </div>
        </nav>
      </header>
      <section className="intro">
        <h1 className="title-desktop">Best Suite of Utility Tools<br />for SunPump, SunSwap & Tron</h1>
        <h1 className="title-mobile">Suite of Tools<br/>For SunPump &SunSwap</h1>
          <div className="intro-buttons">
            <a href="https://t.me/SunToolsPortal" className="button button-primary">Join Community &rarr;</a>
            <a href="https://docs.suntools.tech" className="button button-primary">Explore Bots &rarr;</a>
          </div>
      </section>
        <h4 style={{'font-size': '12px', opacity: 0.8, textAlign: 'center'}}>CA - THacKMJfhRAzB2V7n6dR9Mm2w47cCaWWR3</h4>
      <h1 style={{"text-align": "center", "margin-bottom": "100px"}}>SunTools Solutions</h1>
      <AlternatingSections />
      <h1 style={{"text-align": "center", "margin-top": "100px"}}>Revenue Share</h1>
        <RevenueCards />
      <Footer />

    </div>
  );
}

export default App;
