import React from 'react';
import './Footer.css';

function Footer() {
  return (
      <footer className="footer-container">
          <div className="wrapper">
            <div className="footer-column">
              <div className="logo">
                <img src="/logo.svg" alt="" className="logo" />
                <p>SunTools</p>
              </div>
              <p>Best Suite of Utility Tools for SunPump, SunSwap & Tron.</p>
                <h4 style={{'font-size': '12px', opacity: 0.8, marginTop: '10px'}}>CA - THacKMJfhRAzB2V7n6dR9Mm2w47cCaWWR3</h4>
              <div className="footer-icons">
                <a href="https://t.me/SunToolsPortal"><img src="/icons/telegram.svg" alt="Telegram" /></a>
                <a href="https://x.com/SunToolsTron"><img src="/icons/twitter.svg" alt="Twitter" /></a>
                <a href="https://docs.suntools.tech"><img src="/icons/docs.svg" alt="Docs" /></a>
                <a href="https://dexscreener.com/tron/tbxpmwzzcwc1g6gqdxcim3vbj5zu83kdqd"><img src="/icons/dexscreener.svg" alt="DexScreener" /></a>
                <a href="https://www.dextools.io/app/en/tron/pair-explorer/TBxPMwzzcwC1G6gQDXciM3vBj5ZU83KDqD"><img src="/icons/dextools.svg" alt="DexTools" /></a>
              </div>
            </div>

            <div className="footer-column">
              <h2>For Traders</h2>
              <ul className="footer-links">
                <li><a href="https://t.me/SunToolsWalletTrackerBot">Wallet Tracker</a></li>
                <li><a href="https://t.me/SunToolsScannerBot">Token Scanner</a></li>
                <li><a href="https://t.me/TronTrendLive">Trending Channel</a></li>
                <li><a href="https://t.me/SunToolsTracker">SunTools Tracker</a></li>
              </ul>
            </div>

            <div className="footer-column">
              <h2>For Token Devs</h2>
              <ul className="footer-links">
                <li><a href="https://t.me/Tron_BuyTechBot">Tron/SunPump BuyBot</a></li>
                <li><a href="https://t.me/SunPumpMemeTrendingBot">Book Trending</a></li>
                <li><a href="https://t.me/SunPump_New_Tokens">New Token Deploys</a></li>
              </ul>
            </div>
          </div>
      </footer>
  );
}

export default Footer;
