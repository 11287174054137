import React from 'react';

const DownloadWallets = ({ title, wallets }) => {

  const handleDownload = () => {
    // Join the wallet addresses into a single string with each on a new line
    const fileContent = wallets.join('\n');

    // Create a blob with the file content
    const blob = new Blob([fileContent], { type: 'text/plain' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute with a filename
    link.download = title + '.txt';

    // Create a URL for the blob and set it as the href attribute
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
  };

  return (
    <p onClick={handleDownload} style={{'cursor': 'pointer'}}>[Wallets]</p>
  );
};

export default DownloadWallets;
