import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './AlternatingSections.css';

const SECTIONS = [
  {
    title: 'Tron/SunPump BuyBot',
    descriptions: ['Get fastest notifications about purchases of your token. Customize emoji, mininum buy, set custom photo/video/GIF and social links. Set up whale alerts to track big wallets.'],
    link: ['https://t.me/Tron_BuyTechBot', 'https://docs.suntools.tech/suntools-buy-bot/getting-started'],
      buttonText: ["Add to Group", "Docs"],
    itemsHeader: "How to Use",
    items: [
        'Click "Add BuyBot to Group"',
        'Choose your group from the list',
        'Click "Configure Token"',
        'Click "Add Token" in the main menu of the bot',
        'Paste your token\'s contract address',
        'Edit BuyBot settings - add links, set photo/video/GIF etc.',
    ]
  },
  {
    title: 'Wallet Tracker',
    descriptions: [
        'The SunTools Wallet Tracker is a powerful tool that helps you stay updated on all key activities happening within the TRON ecosystem. By using the Tron Wallet Tracker Bot, you can monitor a variety of events in real time.',
        'With the SunTools Wallet Tracker, you can easily monitor wallet activities, making it simpler to follow market movements and the trading strategies of others.'
    ],
    link: ['https://t.me/SunToolsWalletTrackerBot', 'https://docs.suntools.tech/suntools-wallet-tracker/getting-started'],
      buttonText: ["Track Wallets", "Docs"],
      itemsHeader: "Tracked Events",
    items: [
        "SunPump buys/sells",
        "SunPump bonded tokens buys/sells",
        "Token transfers",
        "TRX transfers",
        "New SunPump tokens from dev"
    ]
  },
  {
    title: 'Token Scanner',
    descriptions: [
        'Instantly analyze any token originating from SunPump, whether bonded or non-bonded. With our powerful Token Scanner, you can quickly access crucial details, track real-time activity, and make informed decisions.',
        'Stay ahead in the ever-evolving TRON ecosystem with comprehensive insights on every token.'
    ],
    link: ['https://t.me/SunToolsScannerBot', 'https://docs.suntools.tech/suntools-token-scanner/how-to-use-the-token-scanner'],
      buttonText: ["Scan Tokens", "Docs"],
      itemsHeader: "Scanned Information",
    items: [
        'Contract Address & Liquidity Pool',
        'Social Links & Charts',
        'Dev Wallet Holdings',
        'Price, Market Cap, Liquidity, 24h Volume, Age',
        'Holders Distribution'
    ]
  },
  {
    title: 'Trending',
    descriptions: ['The SunTools Trending Service is designed to give tokens maximum visibility within the TRON ecosystem. Tokens can secure a spot on the trending list through paid slots, which are ranked based on their 24-hour trading volume. The top three slots are reserved for the highest-volume tokens, followed by the next seven tokens in the top ten.',
        'Additional paid slots are ranked accordingly, with high-volume SunPump tokens filling any remaining free positions. This ensures that the most active and popular tokens are prominently featured, making it easier for users to discover trending tokens and stay informed about the market\'s pulse.'],
    link: ["https://t.me/SunPumpMemeTrendingBot", "https://t.me/TronTrendLive"],
    buttonText: ["Book Trending", "Channel"],
      itemsHeader: "How to Book",
    items: [
        "Open @SunPumpMemeTrendingBot",
        "Click \"I'm ready to Trend\"",
        "Paste your token's contract address",
        "Choose desired position & duration",
        "Pay TRX to the diven wallet",
        "Wait for transaction confirmation & press \"Start Trending\""
    ]
  }
];

const InfoCard = styled.div`
  --flow-space: 0.5em;
  --hsl: var(--hue), var(--saturation), var(--lightness);
  flex: 1 1 14rem;
  padding: 1.5em 2em;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  gap: 1.25em;
  color: #eceff1;
  background-color: #2b2b2b;
  border: 1px solid #eceff133;
  border-radius: 15px;
  transition: 400ms background ease;
  will-change: background;

  &:hover {
    --lightness: 95%;
    background: hsla(var(--hsl), 0.1);
  }
`;

const HoverOverlay = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: ${({ opacity }) => opacity};
  -webkit-mask: radial-gradient(
    25rem 25rem at ${({ x, y }) => `${x}px ${y}px`},
    #000 1%,
    transparent 50%
  );
  mask: radial-gradient(
    25rem 25rem at ${({ x, y }) => `${x}px ${y}px`},
    #000 1%,
    transparent 50%
  );
  transition: 400ms mask ease;
  will-change: mask;
`;

function AlternatingSections() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.pageX, y: e.pageY });
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  useEffect(() => {
    document.body.addEventListener("pointermove", handleMouseMove);
    document.body.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.body.removeEventListener("pointermove", handleMouseMove);
      document.body.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div className="alternating-content">
      <div className="wrapper">
        {SECTIONS.map((section, idx) => idx % 2 === 0 ? (
          <div key={`content_section_${idx}`} className={`content-section ${idx % 2 === 0 ? 'text-left' : 'text-right'}`}>
            <div className="text-content">
              <h2>{section.title}</h2>
              {section.descriptions.map(description => <p key={description}>{description}</p>)}

              <div className="text-content-links">
                {section.link.map((link, linkIdx) => (
                  <a key={`link_${linkIdx}`} href={link} target="_blank" rel="noopener noreferrer" className="button button-secondary">{section.buttonText[linkIdx]} &rarr;</a>
                ))}
              </div>
            </div>

            <div className="info-cards">
              <div className="cards-inner">
                <InfoCard
                  hue={165}
                  saturation={82.26}
                  lightness={51.37}
                  className="info-card"
                >
                  <h3 className="card-heading">{section.itemsHeader}</h3>
                  <ul role="list" className="card-bullets flow">
                    {section.items.map((item, key) => (
                      <li key={key} className="card-bullet">{item}</li>
                    ))}
                  </ul>
                </InfoCard>
              </div>
              <HoverOverlay x={mousePosition.x} y={mousePosition.y} opacity={opacity} />
            </div>
          </div>
        ) : (
            <div key={`content_section_${idx}`} className={`content-section ${idx % 2 === 0 ? 'text-left' : 'text-right'}`}>
                <div className="info-cards">
                  <div className="cards-inner">
                    <InfoCard
                      hue={165}
                      saturation={82.26}
                      lightness={51.37}
                      className="info-card"
                    >
                      <h3 className="card-heading">{section.itemsHeader}</h3>
                      <ul role="list" className="card-bullets flow">
                        {section.items.map((item, key) => (
                          <li key={key} className="card-bullet">{item}</li>
                        ))}
                      </ul>
                    </InfoCard>
                  </div>
                  <HoverOverlay x={mousePosition.x} y={mousePosition.y} opacity={opacity} />
                </div>
                <div className="text-content">
                  <h2>{section.title}</h2>
                  {section.descriptions.map(description => <p key={description}>{description}</p>)}

                  <div className="text-content-links">
                    {section.link.map((link, linkIdx) => (
                      <a key={`link_${linkIdx}`} href={link} target="_blank" rel="noopener noreferrer" className="button button-secondary">{section.buttonText[linkIdx]} &rarr;</a>
                    ))}
                  </div>
                </div>
            </div>
        ))}
      </div>
    </div>
  );
}

export default AlternatingSections;